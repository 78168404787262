import React from 'react';
import { FacebookButton, GoogleButton } from '../styles/LoginForm.styles';
import ExternalLink from './ExternalLink';
import Google from './icons/Google';
import Icon from '@mdi/react';
import { mdiFacebook } from '@mdi/js';
import { graphql, useStaticQuery } from 'gatsby';
import FacebookLoginButton from './FacebookLoginButton';
import GoogleLoginButton from './GoogleLoginButton';

interface Props {
    redirectPath?: string;
    cta: string;
}

const AuthButtons: React.FC<Props> = ({ cta, redirectPath = '/' }) => {
    const data = useStaticQuery(graphql`
        query OAuthLinkQuery {
            allOAuthLink {
                nodes {
                    url
                    provider
                }
            }
        }
    `);

    const googleLink = data.allOAuthLink.nodes.find(i => i.provider === 'google');
    const facebookLink = data.allOAuthLink.nodes.find(i => i.provider === 'facebook');

    return (
        <>
            <GoogleLoginButton text={cta + ' with Google'} link={`${googleLink.url}&state=${encodeURIComponent(redirectPath)}`}/>
            <FacebookLoginButton text={cta + ' with Facebook'} link={`${facebookLink.url}&state=${encodeURIComponent(redirectPath)}`}/>
        </>
    );
};

export default AuthButtons;
