import styled from 'styled-components';
import { modularScale } from '../util/modularScale';

export const Formatter = styled.div`
    p {
        margin-bottom: 1.5rem;
    }
    
    h1, h2, h3, h4, h5 {
        font-weight: bold;
        margin-bottom: 1.5rem;
    }
    
    h2 {
        font-size: ${modularScale(0)};
    }
    
    h3 {
        font-size: ${modularScale(0)};
        font-weight: 500;
    }
    
    h4 {
        font-weight: 400;
        font-style: italic;
        text-decoration: underline;
    }
    
    ul, ol {
        margin-bottom: 1.5rem;
    }
    
    li {
        margin-bottom: 0.5rem;
    }
`;
