import React, { useContext, useState } from 'react';
import axios, { handleError } from '../util/axios';
import { loadStripe } from '@stripe/stripe-js';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions, DialogContent, DialogTitle,
    FormControlLabel,
    FormGroup, Slide,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import { CartContext } from './context/CartContext';
import Spinner from './Spinner';
import { grey, slate } from '../util/theme';
import { modularScale } from '../util/modularScale';
import styled from 'styled-components';
import { Alert } from 'antd';
import PayPalLogo from './icons/PayPalLogo';
import PayPalBrandmark from './icons/PayPalBrandmark';
import Icon from '@mdi/react';
import { mdiLockOutline } from '@mdi/js';
import { Link } from 'gatsby';
import { Formatter } from '../styles/Markdown.styles';
import { TransitionProps } from '@material-ui/core/transitions';

const stripePromise = loadStripe(process.env.STRIPE_CLIENT_ID || '');

const Container = styled.div`
	padding: 1rem;
	max-width: 450px;
	background-color: #fff;
    margin: 2rem auto 0;
	
	@media (min-width: 900px) {
	    border-radius: 4px;
	    padding: 2rem;
	    margin-top: 3rem;
	}
`;

const StyledAlert = styled(Alert)`
	margin-bottom: 1rem;
`;

const Heading = styled.h1`
	font-size: ${modularScale(1)};
	font-weight: 400;
	line-height: 1.2;
	text-align: center;
	margin: 1rem 0 2rem;
`;

const StyledFormGroup = styled(FormGroup)`
	margin-bottom: 2rem;
`;

const StyledLabel = styled(FormControlLabel)`
	color: ${grey};

    .MuiFormControlLabel-label {
	    font-size: ${modularScale(0)};
	}
`;

export const CreditButton = styled(Button)`
	padding: 0.75rem;
	border-radius: 4px;
	background-color: ${slate};
	color: #fff;
	font-size: ${modularScale(0)};
	height: 52px;
	margin-bottom: 1rem;
	
	&:focus, &:hover, &:active {
	    background-color: ${slate};
	}
`;

const StyledSpinner = styled(Spinner)`
    width: 24px;
`;

const PayPalButton = styled(Button)`
    color: #003087;
	background-color: #ffc439;
	padding: 0.75rem;
	margin-bottom: 1rem;
	height: 52px;
	
	&:focus, &:hover, &:active {
	    background-color: #ffc439;
	}
`;

const StyledBrandmark = styled(PayPalBrandmark)`
	width: 14px;
	margin-right: 8px;
`;

const StyledLogo = styled(PayPalLogo)`
	width: 75px;
`;

const Rider = styled.div`
    display: flex;
    align-items: center;
	justify-content: center;
	color: ${grey};
	margin-bottom: 2rem;
	
	svg {
	    width: 14px;
	    margin-right: 4px;
	}
`;

const StyledTitle = styled(DialogTitle)`
    display: none;
    
	@media (min-width: 900px) {
	    display: initial;
	    text-align: center;
	    padding: 2rem;
	}
`;

const Terms = styled.div`
	@media (min-width: 900px) {
	    padding: 0 2rem;
		margin: 0 2rem 1rem;
	    height: 50vh;
	    overflow-y: scroll;
	}
`;

const MobileTitle = styled.h1`
	@media (min-width: 900px) {
	    display: none;
	}
`;

const StyledButton = styled(Button)`
 	.MuiButton-label {
		text-transform: uppercase;
	}
	
	&.MuiButton-containedPrimary {
	    color: #fff;
	}
`;

const StyledActions = styled(DialogActions)`
	padding: 1rem;
`;

interface Props {
    terms: string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CheckoutBillingForm: React.FC<Props> = ({ terms }) => {
    const { cartItems } = useContext(CartContext);
    const [stripeIsLoading, setStripeIsLoading] = useState(false);
    const [paypalIsLoading, setPaypalIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [hasAgreed, setHasAgreed] = useState(false);
    const [metRequirements, setMetRequirements] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleAgree = () => {
        setHasAgreed(!hasAgreed);
        setMetRequirements(true);

        // TODO: Record agreement in database.
    }

    const requireAgreement = () => {
        setMetRequirements(false);
    }

    const openDialog = (e) => {
        e.preventDefault();
        setIsOpen(true);
    }

    const onClose = () => {
        setIsOpen(false);
    }

    const onAccept = () => {
        setHasAgreed(true);
        onClose();
    }

    const onDecline = () => {
        setHasAgreed(false);
        onClose();
    }

    const handleStripe = async (event) => {
        if (!hasAgreed) {
            return requireAgreement();
        }

        setStripeIsLoading(true);

        const stripe = await stripePromise;

        let sessionId = '';

        try {
            const response = await axios.post('/stripe/order', {
                products: cartItems.map(i => i.productId)
            })

            sessionId = response.data.id;
        } catch (err) {
            handleError(err, () => {
                setStripeIsLoading(false);
                setError(err.message);
            });
        }

        if (!sessionId) return;

        const result = await stripe?.redirectToCheckout({ sessionId });

        if (result?.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            console.log(result.error);
            setError(result.error.message || '');
        }
    };

    const handlePaypal = async (event) => {
        if (!hasAgreed) {
            return requireAgreement();
        }

        setPaypalIsLoading(true);

        let approveUrl = '';

        try {
            const response = await axios.post('/paypal/order', {
                products: cartItems.map(i => i.productId)
            })

            approveUrl = response.data.approveUrl;
        } catch (err) {
            handleError(err, () => {
                setPaypalIsLoading(false);
                setError(err.message);
            });
        }

        if (!approveUrl) return;

        window.location.href = approveUrl;
    }

    return (
        <Container>
            <Heading>Accept terms</Heading>

            { !metRequirements && <StyledAlert message={'Please accept the terms and conditions before continuing.'} type="error"/> }

            <StyledFormGroup>
                <StyledLabel
                    control={
                        <Checkbox
                            color="default"
                            checked={hasAgreed}
                            onChange={handleAgree}
                            name="agree"
                        />}
                    label={<>I have read and agree to the Pauliano website <a onClick={openDialog}>terms and conditions</a>.</>}
                />
            </StyledFormGroup>

            <Heading>Select payment type</Heading>

            { error && <StyledAlert message={error} type="error"/> }

            {/*<PayPalButton fullWidth variant="contained" onClick={handlePaypal}>*/}
            {/*    { paypalIsLoading ? <StyledSpinner color={'#003087'} /> : <><StyledBrandmark/> <StyledLogo/></> }*/}
            {/*</PayPalButton>*/}
            <CreditButton fullWidth variant="contained" onClick={handleStripe}>
                { stripeIsLoading ? <StyledSpinner color={'#fff'} /> : 'Debit / Credit' }
            </CreditButton>
            <Rider><Icon path={mdiLockOutline} /> Secure and encrypted</Rider>
            <Dialog
                TransitionComponent={Transition}
                fullScreen={fullScreen}
                onClose={onClose}
                open={isOpen}
            >
                <StyledTitle>Terms and Conditions</StyledTitle>
                <DialogContent>
                    <Terms>
                        <MobileTitle>Terms and Conditions</MobileTitle>
                        <Formatter dangerouslySetInnerHTML={{ __html: terms }}/>
                    </Terms>
                </DialogContent>
                <StyledActions>
                    <StyledButton size={'large'} color={'primary'} onClick={onDecline}>Decline</StyledButton>
                    <StyledButton size={'large'} color={'primary'} onClick={onAccept} variant={'contained'}>Accept</StyledButton>
                </StyledActions>
            </Dialog>
        </Container>
    );
};

export default CheckoutBillingForm;
