import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import CheckoutAuthForm from './CheckoutAuthForm';
import { Stepper, Step, StepLabel, Button } from '@material-ui/core';
import CheckoutBillingForm from './CheckoutBillingForm';
import { AuthContext } from './context/AuthContext';

const Container = styled.div``;

const StyledStepper = styled(Stepper)`
	max-width: 600px;
	margin: 1rem auto 0;
	padding: 0 1rem;
`;

interface Props {
    terms: string;
}

const Checkout: React.FC<Props> = ({ terms }) => {
    const steps = ['Customer Info', 'Payment Info', 'Download'];
    const { isAuthenticated } = useContext(AuthContext);
    const [activeStep, setActiveStep] = useState(isAuthenticated ? 1 : 0);

    const handleNext = () => {
        setActiveStep(1);
    };

    const renderStep = () => {
        switch (activeStep) {
            case 0: return <CheckoutAuthForm redirect='/checkout' onFinish={handleNext}/>
            case 1: return <CheckoutBillingForm terms={terms}/>
        }
    }

    return (
        <Container>
            <StyledStepper alternativeLabel activeStep={activeStep}>
                { steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </StyledStepper>
            {renderStep()}
        </Container>
    );
};

export default Checkout;
