import React from 'react';
import SEO from '../seo/SEO';
import Debug from '../Debug';
import styled from 'styled-components';
import MinimalHeader from '../MinimalHeader';
import { AuthProvider } from '../context/AuthContext';
import { CartProvider } from '../context/CartContext';

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

interface Props {
    customSEO?: boolean;
}

const MinimalLayout: React.FC<Props> = ({customSEO, children }) => {
    return (
        <AuthProvider>
            <CartProvider>
                <Container>
                    {!customSEO && <SEO />}
                    <MinimalHeader/>
                    {children}
                </Container>
            </CartProvider>
        </AuthProvider>
    );
};

export default MinimalLayout;
