import React from 'react';

interface Props {
    to: string;
    onClick?: () => void;
}

const ExternalLink: React.FC<Props> = React.forwardRef(({ to, children, onClick, ...props }, ref: any) => {
    return (
        <a href={to} {...props} ref={ref} onClick={onClick} >{children}</a>
    );
});

export default ExternalLink;
