import { Alert } from 'antd';
import { Button } from '@material-ui/core';
import { modularScale } from '../util/modularScale';
import styled, { keyframes } from 'styled-components';
import { background, boxShadow, gradient } from '../util/theme';
import Shimmer from '../components/Shimmer';

export const Container = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	max-width: calc(300px + 4rem);
	padding: 2rem 1rem;
	margin: 0 auto 4rem;
	
	@media (min-width: 768px) {
	    margin-top: 2rem;
	    max-width: calc(300px + 8rem);
	    background-color: #fff;
	    padding: 4rem;
	    box-shadow: ${boxShadow};
	}
	
	@media (min-width: 900px) {
	    margin-top: 4rem;
	}
`;

export const FormContainer = styled.div`
    display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	max-width: 300px;
    margin-bottom: 2rem;
`;

export const Input = styled.input`
	padding: 0.75rem;
    background-color: #fff;
    box-shadow: ${boxShadow};
	border: none;
	border-radius: 8px;
	width: 100%;
	margin-bottom: 0.5rem;
	outline: none;
	
	@media (min-width: 768px) {
	    box-shadow: none;
	    background-color: ${background};
	}
`;

export const SocialButton = styled(Button)`
    position: relative;
	width: 100%;
	background: #fff;
	color: #757575;
	padding: 0.75rem;
	font-size: ${modularScale(0)};
	
	&:hover {
	    background: #fff;
	}
	
	&:active, &:focus, &:hover {
	    color: #757575;
	}
	
	svg {
	    position: absolute;
	    top: auto;
	    bottom: auto;
	    left: 1rem;
	}
`;

export const FacebookButton = styled(SocialButton)`
    background-color: #4267B2;
    color: #fff;
    
    &:active, &:focus, &:hover {
	    color: #fff;
	}
	
	&:hover {
	    background: #4267B2;
	}
	
    svg {
	    width: 30px;
	    left: .9rem;
	}
`;

export const GoogleButton = styled(SocialButton)`
    margin-bottom: 0.5rem;

	svg {
	    width: 24px;
	}
`;


export const Divider = styled.div`
    position: relative;
	margin: 2rem 0;
	color: #a7a7a7;
	
	&:before, &:after {
	    position: absolute;
	    content: '';
	    height: 1px;
	    width: 30px;
	    background-color: #a7a7a7;
	    top: 50%;
	}
	
	&:before {
	    right: 24px;
	}
	
	&:after {
	    left: 24px;
	}
`;

export const StyledAlert = styled(Alert)`
	margin-bottom: 2rem;
	width: 100%;
`;

export const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 1rem;
`;

export const StyledButton = styled(Button)`
	padding: 0.75rem;
	border-radius: 4px;
	background: ${gradient};
	color: #fff;
	font-size: ${modularScale(0)};
	height: 52px;
	
	svg {
	    width: 24px;
	}
`;

export const StyledShimmer = styled(Shimmer)`
    width: 100%;
`

export const ForgotPassword = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 0.5rem;
`;

export const CreateAccount = styled.div`
    
`;
