import {
    Container,
    CreateAccount,
    Divider,
    FacebookButton,
    ForgotPassword,
    FormContainer,
    GoogleButton,
    Input,
    StyledAlert,
    StyledButton,
    StyledForm, StyledShimmer
} from '../styles/LoginForm.styles';
import Icon from '@mdi/react';
import Spinner from './Spinner';
import axios from '../util/axios';
import Google from './icons/Google';
import styled from 'styled-components';
import AuthButtons from './AuthButtons';
import ErrorMessage from './ErrorMessage';
import { useForm } from 'react-hook-form';
import ExternalLink from './ExternalLink';
import { Tooltip } from '@material-ui/core';
import { grey, greyLight } from '../util/theme';
import { graphql, Link, navigate } from 'gatsby';
import { AuthContext } from './context/AuthContext';
import { mdiFacebook, mdiHelpCircle } from '@mdi/js';
import React, { useContext, useEffect, useState } from 'react';

const FormTitle = styled.div`
    display: flex;
    align-items: center;
	margin-bottom: 1rem;
	font-weight: 500;
	
	svg {
	    width: 14px;
	    margin-left: 6px;
	    color: #a8a8a8;
	}
`;

interface Props {
    onFinish: () => void;
    redirect?: string;
}

const CheckoutAuthForm: React.FC<Props> = ({ onFinish, redirect }) => {
    const { login, isAuthenticated } = useContext(AuthContext);
    const { register, handleSubmit, errors } = useForm();
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            onFinish();
        }
    }, [isAuthenticated]);

    const onSubmit = async data => {
        setIsLoading(true);
        tryLogin(data);
    }

    const createNewAccount = async (data) => {
        setIsLoading(true);

        try {
            const response = await axios.post('/register', data);

            login(response.data.token);

            onFinish();
        } catch (err) {
            setIsLoading(false);

            if ([409].includes(err.response.status)) {
                setError('This email has an existing account, and the password you have provided is invalid.');
                return;
            }

            console.log(err.response.status);
            setError('An error occurred. Please try again, or contact support if the issue persists.');
        }
    }

    const tryLogin = async (data) => {
        setIsLoading(true);

        try {
            const response = await axios.post('/login', data);

            login(response.data.token);

            onFinish();
        } catch (err) {
            if (err.response.status === 400) {
                setIsLoading(false);
                setError('The email or password that you have provided is invalid. ' +
                    'Passwords must have at least 8 characters consisting of letters and numbers.');
                return;
            }

            if (err.response.status === 401) {
                return createNewAccount(data);
            }

            setIsLoading(false);
            setError('An error occurred. Please try again, or contact support if the issue persists.');
        }
    }

    return (
        <Container>
            <FormContainer>
                <AuthButtons cta={'Continue'} redirectPath={'/checkout'}/>

                <Divider>or</Divider>

                <FormTitle>
                    Sign In / Create Account
                    <Tooltip title="Sign into an existing account to add new products to that account, or create a new account.">
                        <Icon path={mdiHelpCircle} />
                    </Tooltip>
                </FormTitle>

                { error && <StyledAlert message={error} type="error"/>}

                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                    <ErrorMessage errors={errors} field="email" />
                    <Input name="email" placeholder="email" type="email" ref={register({ required: true })}/>

                    <ErrorMessage errors={errors} field="password" />
                    <Input name="password" placeholder="password" type="password" ref={register({ required: true })}/>

                    <ForgotPassword><Link to={'/forgot-password'}>Forgot password?</Link></ForgotPassword>

                    <StyledButton type="submit" variant="contained">
                        { isLoading ? <Spinner color={'#fff'} /> : 'Continue to Billing' }
                    </StyledButton>
                </StyledForm>
            </FormContainer>

            <Link to={'/sheets'}>Continue Shopping</Link>

        </Container>
    );
};

export default CheckoutAuthForm;
