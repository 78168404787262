import React, { useState } from 'react';
import MinimalLayout from '../components/layouts/MinimalLayout';
import CheckoutComponent from '../components/Checkout';
import SEO from '../components/seo/SEO';
import { graphql } from 'gatsby';

interface Props {
    data: any;
}

const Checkout: React.FC<Props> = ({ data }) => {
    const terms = data.markdownRemark.html;

    return (
        <MinimalLayout customSEO>
            <SEO title={'Checkout'} />
            <CheckoutComponent terms={terms}/>
        </MinimalLayout>
    );
};

export const query = graphql`
    query {
        markdownRemark(frontmatter: { slug: { eq: "terms-and-conditions" } }) {
            html
        }
    }
`;

export default Checkout;
