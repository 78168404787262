import React from 'react';
import { FacebookButton } from '../styles/LoginForm.styles';
import ExternalLink from './ExternalLink';
import Icon from '@mdi/react';
import { mdiFacebook } from '@mdi/js';

interface Props {
    text: string;
    link: string;
}

const FacebookLoginButton: React.FC<Props> = ({ text, link }) => {
    return (
        <FacebookButton component={ExternalLink} to={link} variant="contained">
            <Icon path={mdiFacebook} /> {text}
        </FacebookButton>
    );
};

export default FacebookLoginButton;
