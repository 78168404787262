import React from 'react';
import { GoogleButton } from '../styles/LoginForm.styles';
import ExternalLink from './ExternalLink';
import Google from './icons/Google';

interface Props {
    text: string;
    link: string;
}

const GoogleLoginButton: React.FC<Props> = ({ text, link }) => {
    return (
        <GoogleButton component={ExternalLink} to={link} variant="contained">
            <Google /> {text}
        </GoogleButton>
    );
};

export default GoogleLoginButton;
