import React from 'react';
import { FieldErrors } from 'react-hook-form';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: center;
	width: 100%;
	color: red;
`;

interface Props {
    errors: FieldErrors,
    field: string;
}

const ErrorMessage: React.FC<Props> = ({ errors, field }) => {
    if (!errors[field]) {
        return null;
    }

    const message = () => {
        switch(errors[field].type) {
            case 'pattern': return 'This field is invalid.';
            case 'required': return 'This field is required.';
        }
    }

    return (
        <Container>
            {message()}
        </Container>
    );
};

export default ErrorMessage;
