import React from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import { Link } from 'gatsby';

const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 1rem;
    
    @media (min-width: 900px) {
        padding: 0.5rem;
    }
`;

const StyledLink = styled(Link)`
	max-width: 60%;
	width: 280px;
	margin: 0 auto;
`;

const MinimalHeader: React.FC = () => {
    return (
        <Container>
            <StyledLink to={'/'}>
                <Logo/>
            </StyledLink>
        </Container>
    );
};

export default MinimalHeader;
